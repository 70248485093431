import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import arrow_Icon from '../Assets/Arrow 1.svg'
import Cookies from "js-cookie"

export default function Log_Out(){

    const navigate = useNavigate()

    return(<>
      <button className="log_out_btn flexB" onClick={(e)=>{
         Cookies.remove('access_token')
         Cookies.remove('identity')
         navigate('/')
      }}> <span><img src={arrow_Icon}/></span> Log Out</button>
    </>)
}