import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Log_Out from "../components/Log_Out";
import Loading from "../components/Loading";
import Cookies from "js-cookie";

export default function Event_Details(){
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true);
    const param = new URLSearchParams(window.location.search)
    const [errorAuthorization, setError_Authorization] = useState(null)
    const countDown_interval_Ref = useRef()

    const [countDown, setCountDown] = useState(5)

    useEffect(()=>{
      (async () => {



        const p = new Promise((resolve, reject)=>{
           if(Cookies.get('access_token') !== undefined){
            resolve(Cookies.get('access_token'))
           }else{
            reject('Access token is undefined');
           }
        })

        try {

          const get_Access_Token = await p

          const request = {
            method: "GET",
            headers: { accept: "application/json", 'Authorization': `Bearer ${get_Access_Token}` },
          };
  
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/staff/events`,
            request
          );
  
          // if (!response.ok) return;
          if (!response.ok) {
            console.log(response,response.ok)
            setError_Authorization('UnAuthorized Access, Redirecting to login')
            // setIsLoading(false)
           
            // timeOutRef.current = setTimeout(()=>{
            //   navigate('/')
            // }, 5000)
  
  
            return
          };
  
          const data = await response.json();

          if(null === data){
            setError_Authorization('No Data received from the server, Please try again later')
            return
          }
          setIsLoading(false);
        } catch (err) {
          console.error(err);
          // setIsLoading(false);


          if('Access token is undefined' === err){
            setError_Authorization('UnAuthorized Access, Redirecting to login')
            // setIsLoading(false)
          } 
        }
      })();
   
      return () => {

        clearInterval(countDown_interval_Ref.current)
  
        countDown_interval_Ref.current = null
      }
   
    },[])


    //  CountDown
  useEffect(() => {
    // Only start the countdown if there's an error
    if (errorAuthorization && (errorAuthorization !== 'No Data received from the server, Please try again later')) {
      countDown_interval_Ref.current = setInterval(() => {
        setCountDown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(countDown_interval_Ref.current); // Clear interval when countdown ends
            navigate('/'); // Redirect when countdown finishes
            return 0;
          }
          return prevCount - 1; // Decrement countdown
        });
      }, 1000); 
    }
  
    return () => {
      clearInterval(countDown_interval_Ref.current);
    };
  }, [errorAuthorization]);

    return(<>

    {errorAuthorization && <div className="error-wrapper"><p className="errorMessage">{errorAuthorization} in {countDown}</p></div>}
    <header className="page_header flexB">
      <div className="event-selected-options flexB">
       {param.get('event') && <p>{param.get('event')}</p>}
       {param.get('date') && <p>{param.get('date')}</p>}
       {param.get('time') && <p>{param.get('time')}</p>}
       {param.get('venue') && <p>{param.get('venue')}</p>}
      </div>
      <Log_Out/>
    </header>
      
    
    {isLoading && <Loading/>}
     <div >
     {!isLoading && (
      !errorAuthorization && <div className="reports-Or-IssueTickets-card">
      <p> {param.get('event') && <p>{param.get('event')}</p>}</p>
     <div className="buttons flexB"><button className="reports_Btn" >Reports</button>
          <button className="issue_Tickets_Btn" onClick={()=>{
            navigate(`/qrScanner?id=${param.get('id')}&event=${param.get('event')}&venue=${param.get('venue')}&date=${param.get('date')}&time=${param.get('time')}`)
          }}>Issue Tickets</button>
     </div>
      
      </div>
     )}
    </div>   
    </>)
}