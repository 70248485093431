import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import Page_Load from "./Page_Load"
import Cookies from "js-cookie"
import whatsApp_Icon from '../Assets/whatsapp.svg'
import arrow_icon from '../Assets/Arrow 1.svg'
import verify_svg from "../Assets/Verify.svg"
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function Login(){
    const [whatsApp_Message, setWhatsApp_Message] = useState("")
    const [whatsApp_Code, setWhatsApp_Code] = useState("")
    const [whatsApp_Redirect, setWhatsApp_Redirect] = useState("")
    const whatsApp_Code_Ref = useRef()
    const [mobileNo, setMobileNo] = useState("")
    const [errorNo, setErrorNo] = useState("")
    const [otpErrorMsg, setOTP_ErrorMsg] = useState("")
    const [email, setEmail] = useState("")
    const login_ref = useRef()
    const mobileInputRef = useRef()
    const [otpMessage, setOtpMessage] = useState("Continue")
    const [verificationMessage, setVerificationMessage] = useState("Verify")
    const [otpInfo, setOtpInfo] = useState()
    const OTP_Container_Ref = useRef()
    const [verifyNo, setVerifyNo] = useState("")
    const [isVerficationSuccess, setIsVerficationSuccess] = useState(false)
    const navigate = useNavigate()
    const KMSI_Ref = useRef()
    const [isKMSI, setIsKMSI] = useState()


    // useEffect(() => {
    //     (async () => {
    //       const kmsi_Cookie = new Promise((resolve, reject) => {
    //         const kmsi = localStorage.getItem("kmsi");
    //         if (kmsi !== null) {
    //           resolve(kmsi === "true"); // Convert string to boolean
    //         } else {
    //           reject("KMSI is not available");
    //         }
    //       });
      
    //       try {
    //         const kmsi = await kmsi_Cookie;
    //         setIsKMSI(kmsi);
    //       } catch (err) {
    //         console.error(err);
    //       }
    //     })();

    //   }, []);
      

    
    async function get_WhatsApp_Code(){
        try{
            const payload = {
               channel: "WhatsApp"
            }
            const request = {
                method: 'POST',
                headers:{
                    'accept': 'application/json'
                },
                body: JSON.stringify(payload)
            }

           const response = await fetch (`${process.env.REACT_APP_API_URL}/auth/external`, request)


           const data = await response.json()

           const whatsApp_Data = { redirect: data.redirect, message: data.message.split(" ")}
           
           console.log(whatsApp_Data)
           

           setWhatsApp_Message(whatsApp_Data.message)
           setWhatsApp_Code(whatsApp_Data.message[2])
           setWhatsApp_Redirect(whatsApp_Data.redirect)

        //    setTimeout(()=>{
        //     window.open(whatsApp_Data.redirect, "myTab")
        //    },2000)
            
        //    const message = []
        //    window.location.href = data2.redirect
        }catch(err){
          console.error("Error:", err)
        }
    }
    
    function copy_Code_WA(){
        whatsApp_Code_Ref.current.select()
        whatsApp_Code_Ref.current.setSelectionRange(0, 99999);

        document.execCommand("copy");
    }

    function redirect_To_WhatsApp(){
       if(whatsApp_Message){
        console.log(whatsApp_Message)
        window.open(whatsApp_Redirect, "myTab")
       }
    }


    
    async function requestOTP(){
        // console.log("from Request OTP:" ,mobileNo) 
        
      //Number Validation
       if(!mobileNo){
        setErrorNo("Phone number is required.")
        return
       } else if(!isValidPhoneNumber(mobileNo)){
        setErrorNo('Invalid phone number.')
        return
       }else{
        setErrorNo('')
       }
        
        try{
            setOtpMessage("Loading...")

            const payload = {
                mobile: mobileNo, email, device: Cookies.get('device'), keepMeSignedIn: isKMSI, 
            }
        

            const request = {
                method: 'POST',
                // credentials: 'include',
                headers: {'accept': 'application/json'},
                
                body: JSON.stringify(payload)
            }
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, request)

            // const response = await fetch('https://posttestserver.dev/p/koqh7caz2muc45se/post', request)

            if(!response.ok) throw new Error('Failed to request OTP');

            const result = await response.json();
            console.log(result)
            Cookies.set('identity', result.identity, {expires: 7})


            //HardCode OTP to 1234 for now
            setOtpInfo("1234")

            login_ref.current.classList.add('grow')
            mobileInputRef.current.classList.add('vanish')
            OTP_Container_Ref.current.classList.add('visible')
            setOtpMessage("Success")
            setWhatsApp_Message("")




            
        }catch(err){
            console.error(err)
            setOtpMessage("Continue")
            setOTP_ErrorMsg("Error sending OTP, please try again")

            setTimeout(()=>{
                setOTP_ErrorMsg("")
            },3500)
        }
    }

    async function verifyOTP(){

    try{

        setVerificationMessage("Verifying...")    
       
    const payload = {
        device: Cookies.get('device'), identity: Cookies.get('identity'), otp: Number(otpInfo)
      }


    // console.log(payload)

    const request = {
        method: 'POST',
        headers: {'accept': 'application/json'},
        body: JSON.stringify(payload)
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verify`, request)

    const data = await response.json()

    console.log(data)



    


    if(data.access_token !== ""){
       Cookies.set('access_token', data.access_token, {expires: 7, secure: true})
       setVerificationMessage("Success")
       setIsVerficationSuccess(true)

       navigate("/Events")
    }else{
        alert(data.reason)
    }

    }catch(err){
      console.error("Error:", err)
    }

    }

    async function login_To_DashBoard() {
        

        try{
            const request = {
                method: 'GET',
                headers: {
                     'Authorization': `Bearer ${Cookies.get('access_token')}`
                }
            }
    
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, request)
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();

            console.log(data)
        }catch(err){
          console.error("Error:", err)
        }

    }

    function keepMeSignedIn(e){
        //  console.log(e.target.checked)
        //  localStorage.setItem('kmsi', e.target.checked )
         setIsKMSI(e.target.checked)
    }

    return (<>
    <Page_Load/>
    <div className="error-wrapper">
    {errorNo && <p className="errorMessage">{errorNo}</p>}
    {otpErrorMsg && <p className="errorMessage">{otpErrorMsg}</p>}
    </div>
    {whatsApp_Message && <div className="whatsApp-Wrapper">
         <p className="message text-center">{whatsApp_Message[0]} {whatsApp_Message[1]} {whatsApp_Message[3]} {whatsApp_Message[4]}</p>
         <div className="whatsapp_code flexB"> 
         <input type="text" value={whatsApp_Code} className="whatsApp_code" ref={whatsApp_Code_Ref}/>
          <button className="copy_btn" onClick={copy_Code_WA}>Copy</button> 
          <button className="take_Me_There" onClick={redirect_To_WhatsApp}>Take me there!</button> </div>
        </div>}
     <div className="login-Card" ref={login_ref}>
    <div className="mobileInput_OTP-Wrapper">
   
       
     {otpInfo? <h2>Verify your number</h2>: <h2>Welcome</h2>}

   
    <div className="mobile_Input flexB" ref={mobileInputRef}>


     
    <div className="whatsApp-wrapper">
    <button className="whatsapp_Btn flexB" onClick={get_WhatsApp_Code}>
    <img src={whatsApp_Icon} alt="WhatsApp Icon"/>    
    <span>
    Connect with WhatsApp
        </span></button>  
    </div> 

    <div className="divider flexB">
       
        <p>Or sign in with Email/Number</p>

    </div>
    <input type="email" id="emailInput" value={email} placeholder="Email Address" onChange={(e)=>{setEmail(e.target.value)}}/>

    
    

    <PhoneInput
    placeholder = "Phone number"
    value = {mobileNo}
    onChange={(number)=>{
        setMobileNo(number)
        setErrorNo('')
    }}
    defaultCountry="LK"
    smartCaret = {true}
    international = {false}
    countrySelectProps={{disabled: true}}
    // disabled = {true}
    />

   

    <div className="keep-me-signed-in flexB">
        <input type="checkbox" id="kmsi_cb" onChange={keepMeSignedIn} checked={isKMSI}/>
        <label htmlFor="kmsi_cb">Keep me signed in</label>
    </div>   
    <button className="requestOTP-Btn flexB" onClick={requestOTP}><span>{otpMessage}</span> <img src={arrow_icon}/></button>

    </div>

    <div ref={OTP_Container_Ref} className="otp-Wrapper flexB">

        <div className="otp flexB">
        <input id="OTP-Input" value={verifyNo} onChange={(e)=>{setVerifyNo(e.target.value)}}name="OTP-Input" type="password" placeholder="Enter OTP"/>
        <button className="verifyOTP-Btn" onClick={verifyOTP}>{verificationMessage}</button>
        {isVerficationSuccess && <button className="login-btn" onClick={login_To_DashBoard}>Login</button>}
        </div>

        <div className="image">
            <img src={verify_svg}/>
        </div>
    </div>
    </div>
     </div>

    

  
    
    </>)
}
export default Login