import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./containers/Login";
import QR_Scanner from "./containers/QR_Scanner";
import Event_Details from "./containers/Event_Details";
import { createContext, useEffect, useState } from "react";
import Page_Load from "./containers/Page_Load";
import Events from "./containers/Events";
import Cookies from "js-cookie";
import mqtt from "mqtt";

export const MqttContext = createContext();

function App() {
  const [client, setClient] = useState(null);
  const [deviceId, setDeviceId] = useState(null);


  // First get the deviceID from the cookies
  useEffect(() => {
    if (Cookies.get('device')) {
      setDeviceId(Cookies.get('device'));
    }
  }, []);

  //Next trigger useEffect once the deviceId isnt null
  useEffect(() => {
    if (deviceId) {
      const mqttClient = mqtt.connect('wss://feb1a65f.ala.asia-southeast1.emqxsl.com:8084/mqtt', {
        protocolVersion: 5,
        clean: false,
        properties: {
          sessionExpiryInterval: 300
        },
        clientId: deviceId,
        username: 'tlku1',
        password: 'tlk54!23'
      });

      mqttClient.on('connect', () => {
        console.log('Connected to Broker!');
        setClient(mqttClient);

        mqttClient.subscribe(`${deviceId}/#`, (err) => {
          console.log({err});
          if (!err) {
            console.log(`Subscribed to topic: ${deviceId}/#`);
          }
        });

        mqttClient.on('message', (topic, message) => {
          console.log(`Message received ${topic}: ${message.toString()}`);
        });
      });

      mqttClient.on('error', (err) => {
        console.error('Connection error', err);
      });

      mqttClient.on('disconnect', (err) =>{
        console.error('Connection disconnected', err)
      })

      return () => {
        mqttClient.end();
      };
    }
  }, [deviceId]);

  return (
    <Router>
      <MqttContext.Provider value={client}>
        <Page_Load />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/events" element={<Events/>}/>
          <Route path="/event-Details" element={<Event_Details/>}/>
          <Route path="/qrScanner" element={<QR_Scanner />} />
        </Routes>
      </MqttContext.Provider>
    </Router>
  );
}

export default App;
