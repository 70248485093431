import { useState, useRef } from "react";
import search_Icon from "../Assets/Search Icon.svg"
import filter_Icon from "../Assets/Filter.svg"
import drop_Down_Icon from '../Assets/drop down.svg'
export default function Search_Bar (){
    const [searchInput, SetSearchInput] = useState('')
    const list_Grid_Ref = useRef()
    const [isListView, setIsListView] = useState(true)


   //  useEffect(() => {
   //    (async () => {
   //      const isList_View_Cookie = new Promise((resolve, reject) => {
   //        const kmsi = localStorage.getItem("is_List_View");
   //        if (kmsi !== null) {
   //          resolve(kmsi === "true"); // Convert string to boolean
   //        } else {
   //          reject("KMSI is not available");
   //        }
   //      });
    
   //      try {
   //        const kmsi = await kmsi_Cookie;
   //        setIsKMSI(kmsi);
   //      } catch (err) {
   //        console.error(err);
   //      }
   //    })();
   //  }, []);


    function view_By_List_Or_Grid(){
       setIsListView(!isListView)

      //  localStorage.setItem('')

    //    ...more code to be updated
    }

    return (<>
    
   <div className="search-Bar flexB">
    <div className="search-wrapper flexB">
    <img src={search_Icon} alt="search_Icon"/>    
    <input placeholder="Search"/> 
    </div>
    <div className="options flexB">
    <button className="today_Btn">Today</button>
    <button className="filter_By_Dates flexB"> <span> <img src={filter_Icon} alt="Filter_Icon"/> </span> Filter by date <span><img src={drop_Down_Icon} alt="drop down icon"/></span> </button>
    <div className={isListView? `list_Grid_View`: `list_Grid_View active`} ref={list_Grid_Ref} onClick={view_By_List_Or_Grid}>
       <div className="list_Grid_btn flexB"><button className={!isListView? 'disable': ''}>List</button> <button className={isListView? 'disable': ''}>Grid</button></div> 
       <div className="lever"></div> 
    </div>
    </div>
   </div> 
    </>)
}