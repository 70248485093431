import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Search_Bar from "../components/Search_Bar";
import Log_Out from "../components/Log_Out";
import Loading from "../components/Loading";
import Cookies from "js-cookie";

export default function Events() {
  const navigate = useNavigate();
  const [list_of_dates, setList_of_Dates] = useState([]);
  const [eventData, setEventData] = useState();
  const [categorisedEvents, setCategorised_Events] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorAuthorization, setError_Authorization] = useState(null)
  const countDown_interval_Ref = useRef()
  const [countDown, setCountDown] = useState(5)

  useEffect(() => { 
    // console.log("Access Token Effect Triggered:", access_Token);

      (async () => {

        const p = new Promise((resolve, reject)=>{
           if(Cookies.get('access_token') !== undefined){
            // resolve(localStorage.getItem('access_token'))
            resolve(Cookies.get('access_token'))
           }else{
            reject('Access token is undefined');
           }
        })

        try {

          const get_Access_Token = await p

          const request = {
            method: "GET",
            headers: { accept: "application/json", 'Authorization': `Bearer ${get_Access_Token}` },
          };
  
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/staff/events`,
            request
          );
  
          if (!response.ok) {
            setError_Authorization('UnAuthorized Access, Redirecting to login')
            return
          };
  
          const data = await response.json();
          
          if(null === data){
            setError_Authorization('No Data received from the server, Please try again later')
            return
          }
          
          setEventData(data);
          console.log(data)
          setIsLoading(false);
          
        } catch (err) {
          console.error(err);

          if('Access token is undefined' === err){
            setError_Authorization('UnAuthorized Access, Redirecting to login')
          } 
        }
      })();
   

     

    return () => {

      clearInterval(countDown_interval_Ref.current)

      countDown_interval_Ref.current = null
    }
  }, []);


//  CountDown
  useEffect(() => {
    // Only start the countdown if there's an error
    if (errorAuthorization && (errorAuthorization !== 'No Data received from the server, Please try again later')) {
      countDown_interval_Ref.current = setInterval(() => {
        setCountDown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(countDown_interval_Ref.current); // Clear interval when countdown ends
            navigate('/'); // Redirect when countdown finishes
            return 0;
          }
          return prevCount - 1; // Decrement countdown
        });
      }, 1000); 
    }
  
    return () => {
      clearInterval(countDown_interval_Ref.current);
    };
  }, [errorAuthorization]);
  




  useEffect(() => {
    if (!eventData) return;

    const datesArray = [];

    eventData.forEach((item) => {
      item.venues.forEach((venue) => {
        venue.dates.forEach((dateObj) => {
          datesArray.push(dateObj.date);
        });
      });
    });

    const uniqueDates = [...new Set(datesArray)];
    setList_of_Dates(uniqueDates);
  }, [eventData]);

  useEffect(() => {
    if (!eventData) return;

    const datesArray = [];

    eventData.forEach((item) => {
      item.venues.forEach((venue) => {
        venue.dates.forEach((dateObj) => {
          list_of_dates.forEach((date) => {
            if (dateObj.date === date) {
              datesArray.push({
                id: item.id,
                date,
                event_name: item.name,
                venue_info: { id: venue.id, name: venue.name },
                times: dateObj.times,
              });
            }
          });
        });
      });
    });
    setCategorised_Events(datesArray);
  }, [eventData, list_of_dates]);


  const toEventsDetails = (e, id, event_name, venue_info, date, time)=>{
  
    navigate(`/event-Details?id=${id}&event=${event_name}&venue=${venue_info.name}&date=${date}&time=${time}`)

  
  }

  //testing
  // useEffect(()=>{
  //   console.log(countDown)
  //  }, [countDown])

  return (
    <>
      {errorAuthorization && <div className="error-wrapper"><p className="errorMessage">{errorAuthorization} in {countDown}</p></div>}
      <header className="page_header flexB">
      <h2>Events</h2>
      <Log_Out/>
      </header>
      <Search_Bar page={''} />
      <div className="events-Table">
        {isLoading && <Loading/>}
        
        {list_of_dates.map((_date) => (
          <div className="date-categorized-events flexB" key={_date}>
            <div className="date">{_date}</div>
            <div className="list_of_events">
              {categorisedEvents &&
                categorisedEvents.map(({ id, date, event_name, venue_info, times }) => {
                  if (date === _date) {

                   return(
                    times.map(({time})=>
                      (
                      <div className="event flexB" key={id}>
                       <div className="times">
                         <p>{time}</p>
                        </div>
                       <p className="event_name">{event_name}</p>
                       <button className="select_event_Btn" onClick={(e)=>{toEventsDetails(e, id,event_name, venue_info, _date, time)}}>Select</button>
                     </div>
                     )
                   )
                   )
                    
                  }
                  return null;
                })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
