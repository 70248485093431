export default function Loading(){
    return(<>
    <div className="wrapper">
     
    <div className="loading-card flexB"> 
    <div className="message"></div>
     <div className="loading"></div>
    </div>
    </div>
    </>)
}