import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import loading_Icon from '../Assets/Loading-Icon.svg'
import useGetOS from '../hooks/Detect_Device';

function Page_Load() {
    const location = useLocation();
    const navigate = useNavigate();
    const [public_IP_Address, set_Public_IP_Address] = useState()
    const [pageLoad, setPageLoad] = useState(false)
    const getOS = useGetOS()

    useEffect(() => {
     console.log({getOS})
    //    console.log({'os': platform.os, 'Browser': platform.name, 'Description': platform.description, 'Product': platform.product, 'Manufacturer': platform.manufacturer, screenResolution: {width: window.screen.width, height: window.screen.height}})

        async function get_Public_IP(){
           try{
             const response = await fetch(`${process.env.REACT_APP_IP_URL}`)
             const data = await response.json()

            //  console.log({get_Public_IP: data})
             set_Public_IP_Address(data.ip)
           }catch(err){
             console.error(err)
           }
        }

        get_Public_IP()

        async function getCookie(device = null) {

            try {


                const payload = device
                //if Cookie exists
                ? { device, page: location.pathname }
                //if not
                : { page: location.pathname, 
                    os: getOS,
                    browser: null,
                    public_IP_Address,
                    screenResolution: {width: window.screen.width, height: window.screen.height},
                    darkTheme: false,
                };
                    


                // console.log(payload)

            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            };
               //if no cookies
               if(!device){
                const response = await fetch(`${process.env.REACT_APP_API_URL}/device`, request);

                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json()

                 if (data.device !== Cookies.get('device')) { Cookies.set('device', data.device, { expires: 7, path:'/' , sameSite: 'Lax'});
                   
                    // console.log('Cookie values are NOT the same');
                    navigate('/'); 
                }

                

               }
               //if device cookies exists but values are not the same
               else if(device && device !== Cookies.get('device')){

                const response = await fetch(`${process.env.REACT_APP_API_URL}/device`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ page: location.pathname,
                                           os: getOS, 
                                           browser: null,
                                           public_IP_Address,
                                           screenResolution: {width: window.screen.width,
                                           height: window.screen.height},
                                           darkTheme: false,    
                                        }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json()

                 if (data.device !== Cookies.get('device')) {
                    Cookies.set('device', data.device, { expires: 7 });
                    // console.log('Cookie values are NOT the same');
                    // navigate('/'); 
                }



               }
               
               //if cookies exist
               else{

                const response = await fetch(`${process.env.REACT_APP_API_URL}/track`, request)


                // console.log(response.ok)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                

               }
            } catch (err) {
                console.error('Error:', err);
            }
        }

        //Find Cookie
        const device = Cookies.get('device');
        console.log('Device cookie:', device);

        // Call getCookie based on whether the cookie exists
        getCookie(device);




       

        //Only fire the API call below if user is not in the login page
        if(location.pathname === '/') return

        (async () => {
            try{


                const payload = {
                    identity: Cookies.get('identity')

                }

                const request = {
                    method : 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(payload),
                }


                const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, request)

                if (!response.ok) {
                    console.error("Failed to refresh token:", response.statusText);
                    return null;
                }
                 
                const data = await response.json();
                console.log("New Access Token:", data.access_token);

                
                console.log(data)
            }catch(err){
                console.error("Error refreshing token:", err);
                return null;
            }
        })()

        console.log({whereAmI: location.pathname})
    }, [location.pathname, navigate, public_IP_Address]);

    return (<>
    {pageLoad && <div className='page-load'><img className="loading_Icon" src={loading_Icon} alt="loading..."/></div>}
    </>)
}

export default Page_Load;
