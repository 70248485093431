import { useState, useEffect, useRef, useContext } from "react"
// import { Html5QrcodeScanner } from "html5-qrcode"
import { QrReader } from "react-qr-reader"
import loading_Icon from '../Assets/Loading-Icon.svg'
import Page_Load from "./Page_Load"
import { MqttContext } from "../App"
import Log_Out from "../components/Log_Out"
import Cookies from "js-cookie"
import Loading from "../components/Loading"
import { useNavigate } from "react-router-dom"

function QR_Scanner(){
    const[referenceNum, setReferenceNum] = useState("")
    const[selectedData, setSelectedData] = useState(null)
    const[isScan, setIsScan] = useState(false)
    const apiCallBtnRef = useRef()
    const displayDataRef = useRef()
    const overlayRef = useRef()
    const [ticketDetail, setTicketDetail] = useState()
    const [seatID, setSeatId] = useState(null)
    const[issued, setIssued] = useState(false)
    const issueBtnRef = useRef()
    const [errorAuthorization, setError_Authorization] = useState(null)
    const [errorRefCode, setErrorRefCode] = useState(null)
    const navigate = useNavigate()
    const countDown_interval_Ref = useRef()

    const [countDown, setCountDown] = useState(5)


    const mqttClient = useContext(MqttContext)

    const param = new URLSearchParams(window.location.search)

    useEffect(()=>{
      if(!mqttClient) return

      mqttClient.subscribe('xyz', (err)=>{
        console.log({err})
        if(!err){
            console.log('Subscribed to topic: xyz', 'and im in schedule a call component')
          
        }
    })
    },[])


        //  CountDown
  useEffect(() => {
    // Only start the countdown if there's an error
    if (errorAuthorization && (errorAuthorization !== 'No Data received from the server, Please try again later')) {
      countDown_interval_Ref.current = setInterval(() => {
        setCountDown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(countDown_interval_Ref.current); // Clear interval when countdown ends
            navigate('/'); // Redirect when countdown finishes
            return 0;
          }
          return prevCount - 1; // Decrement countdown
        });
      }, 1000); 
    }
  
    return () => {
      clearInterval(countDown_interval_Ref.current);
    };
  }, [errorAuthorization]);
  
    useEffect(() => {
        // Cleanup function to stop the camera on unmount
        return () => {
          const videoTracks = document.querySelector('video')?.srcObject?.getTracks();
          if (videoTracks) {
            videoTracks.forEach((track) => track.stop());
          }
        };
      }, [isScan]);

    //  Triggers when there are changes in the reference textbox
     useEffect(()=>{

      if(referenceNum.length === 12){
        apiCallBtnRef.current.classList.add('visible')
        return
      }

      apiCallBtnRef.current.classList.remove('visible')
     },[referenceNum])

    
    async function fetchData() {
   
      setErrorRefCode(null)

      const p = new Promise((resolve, reject)=>{
        if(Cookies.get('access_token') !== undefined){
         // resolve(localStorage.getItem('access_token'))
         resolve(Cookies.get('access_token'))
        }else{
         reject('Access token is undefined');
        }
     })



  
      try {

           const get_Access_Token = await p

           const request = {
            method: "GET",
            headers: { accept: "application/json", 'Authorization': `Bearer ${get_Access_Token}` },
          };

          const response = await fetch(`${process.env.REACT_APP_API_URL}/issue/${referenceNum}`, request);



          console.log(response)
          if(!response.ok){
            
            return
          }

          const parseData = await response.json();
          
          
          setSelectedData(parseData);
          console.log(parseData);
  
          overlayRef.current.classList.add('active');
          displayDataRef.current.classList.add('visible');
      } catch (error) {
          
          console.error('Error fetching data:', error);
          if('Access token is undefined' === error){
            setError_Authorization('UnAuthorized Access, Redirecting to login')
            return
          }

          setErrorRefCode(`Falied to fetch data. Reference Code: ${referenceNum}`)
      }
  }
  




  
  function issueTicket(e, _issued){
    const seatId = e.target.closest('.ticket-Wrapper').querySelector('.seatId').textContent
   setSeatId(seatId)
   setIssued(!_issued)
   issueBtnRef.current = e.target 
  }




  function closeDetails_SideBar(){
    overlayRef.current.classList.remove('active')
    displayDataRef.current.classList.remove('visible')

    setTimeout(()=>{
      window.location.reload();
    }, 549)
  }


   useEffect(()=>{
          let ticketDetail;
          
          if (selectedData) {    
             ticketDetail = selectedData.seatDetail.find(({ seat }) => {
          
                return seat === seatID; // Directly return the condition
            });
        
            console.log(ticketDetail); // Check the found element
        }
        

          if(seatID){
            
               (
                async ()=>{

                  console.log({seatID})
                  const p = new Promise((resolve, reject)=>{
                    if(Cookies.get('access_token') !== undefined){
                     // resolve(localStorage.getItem('access_token'))
                     resolve(Cookies.get('access_token'))
                    }else{
                     reject('Access token is undefined');
                    }
                 })
            

                  try {
                      
                      const get_Access_Token = await p
                      
                      const response = await fetch(`${process.env.REACT_APP_API_URL}/issue/${referenceNum}`, {
                          method: 'PUT',
                          headers: {'Content-Type': 'application/json' ,'Authorization': `Bearer ${get_Access_Token}` },
                          body: JSON.stringify({
                              refcode: `${referenceNum}`,
                              tickets: [
                                  { ...ticketDetail, issued }
                              ]
                          })
                      });
              
                      const data = await response.json();
                      
                      issueBtnRef.current.classList.add('disabled');
                      console.log('Success:', data);
                      // setIsIssueLoading(false);
                  } catch (error) {
                      console.error('Error:', error);
                  }
              }
               )()
              
        
            }
        },[seatID])

    



  

    return (
        <>

        <Page_Load/>
        {errorAuthorization && <div className="error-wrapper"><p className="errorMessage">{errorAuthorization} in {countDown}</p></div>}
        {errorRefCode && <div className="error-wrapper"><p className="errorMessage">{errorRefCode}</p></div>}
        <header className="page_header flexB">
      <div className="event-selected-options flexB">
       {param.get('event') && <p>{param.get('event')}</p>}
       {param.get('date') && <p>{param.get('date')}</p>}
       {param.get('time') && <p>{param.get('time')}</p>}
       {param.get('venue') && <p>{param.get('venue')}</p>}
      </div>
      <Log_Out/>
    </header>


         <div className="app flexB">

         <div className="qr-code flexB">
          <div className="overlay" ref={overlayRef} onClick={closeDetails_SideBar}></div>
         <div>
         <div id="reader">
         {isScan &&  
         <QrReader
         onResult={(result, error) => {
           if (!!result) {
             const textResult = result?.text; // Extract the text from the result object
             setReferenceNum(textResult); // Ensure this is a string
           
             setTimeout(() => {
               apiCallBtnRef.current.click();
             },100);
           }
       
           if (!!error) {
            //  console.info(error);
           }
         }}
         style={{ width: '100%' }}
       />
       

          }</div> 
         <div className="ref-wrapper flexB">
          <input className="referenceNo" maxLength={12} placeholder="Enter Reference No (e.g., REF12345I710)" value={referenceNum} type="text" onChange={(e)=>{setReferenceNum(e.target.value)} }/>
          <div className="qr-buttons flexB">
          <button className="submit_btn" ref={apiCallBtnRef} onClick={fetchData}>Submit</button>
          <p>OR</p>
          <button className="scan_QR_btn" onClick={()=>{ setIsScan(!isScan) }}>Scan QR</button>
          </div>
          
         </div>
         </div>
         </div>
        
         

        
         <div className="displayData" ref={displayDataRef}>
            <div className="wrapper">
            {selectedData? <div>
            
        
            <div>
            <h3>Customer Details</h3>
            <p className="userEmail">Email: {selectedData.user.email}</p>
            <p className="userMobile">Mobile: {selectedData.user.mobile}</p>
            <div><p>Reference No: <b>{referenceNum}</b></p></div>
            </div>
            <br/>
            <br/>

            
    

            <h4 className="text-center">Seat Detail</h4>
            <div className="ticket_Categories flexB">
               {

                
                selectedData.seatDetail&& selectedData.seatDetail.map(({category, seat, issued})=>(
                 <>
                  
                    <div className="ticket-Wrapper flexB"key={category.id}>
                    <div> <p>Ticket Category: {category.category}</p>
                    <p>Seat Number: <span className="seatId">{seat}</span></p>
                    </div>

                    <div className="issue-btn-Wrapper flexB">
                    <button className={issued?`issueTicket_Btn disabled`:`issueTicket_Btn`} onClick={(e)=>{issueTicket(e, issued)}}>Issue</button>
                    </div>
                    </div>
                  
                 
                 <br/>
                 </>
                ))
            }
            </div>
            <div className="close-Btn-Wrapper flexB"><button className="closeModal-Btn" onClick={closeDetails_SideBar}>Close</button></div>
           

            </div>: <p>Data Not Found</p>} 
            </div>
         </div>
         </div>
        

        

        

         

         
</>
        
    )
}
export default QR_Scanner